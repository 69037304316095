.spin-container {
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.zoom-container  {
    animation: zoom-in-zoom-out;
    animation-duration: 1000ms;
    animation-timing-function: ease; 
    animation-iteration-count: infinite;
}

@keyframes zoom-in-zoom-out {
    0% {
        scale: 100%;
    }
    50% {
        scale: 150%;
    }
    100% {
        scale: 100%;
    }
}

.zoom-spin-container  {
    animation: zoom-in-zoom-out 1s ease infinite, spin linear infinite;
}